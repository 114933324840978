@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    font-family: 'Roboto Mono', monospace;
    
  }

}
.App {
  text-align: center;
}
h1{
  font-size:  clamp(2rem,3vw + 1rem ,5rem);
}
.cont {
  text-align: center;
  height:93svh;
  position:relative;
}
.containerz{
  height:100svh;
}
.bottom{
position: absolute;
inset: auto 0 0;
bottom:0;
padding: 0.5rem;
background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
opacity: 0.5;
}
.padding0_5{
  padding: 0.5rem;
}

.contattiform{
  display: flex;
  justify-items: center;
  text-align: center;
  width: 100%;
  align-items: stretch;
  justify-content: space-evenly;
  flex-direction: column;
}
.rowSecond{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
}
.menu{
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.center{
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.corouselBottoneSinistro{
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 50%;
    border: none;
  background: none;
    /* opacity: 0.3; */
    height: 2.3rem;
    border-radius: 80%;
}
.corouselBottoneDestro{
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    border: none;
    background: none;
    /* opacity: 0.3; */
    height: 2.3rem;
    border-radius: 80%;
}

.buttonSend{
  display: block;
  padding: 8px 28px;
  border-radius: 20px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .02em;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.overflow{
  overflow-x: auto;
  max-height: 72svh;
}